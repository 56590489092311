import { getCookie } from 'lib/nextjs/nextCookie'
import { fetcher } from 'lib/api/fetcher'
import { API_URL, TOKEN_COOKIE } from 'config'
import { GqlApiError, GqlError } from './GqlApiError'


export const gqlUpdateAvatar = async <Data>(
  image: File
) => {
  const token = getCookie(TOKEN_COOKIE)

  // --- prepare the form data
  const formData = new FormData()
  const operations = {
    query: `mutation ($image: Upload!) {
      updateAvatar(image: $image) {
        avatar { url }
      }
    }`,
    variables: {
      image: null,
    },
  }
  formData.append('operations', JSON.stringify(operations))

  const map = {
    '0': ['variables.image'],
  }
  formData.append('map', JSON.stringify(map))
  formData.append('0', image)

  const { data, errors } = await fetcher<{
    data: Data | undefined
    errors: GqlError[] | undefined
  }>(API_URL, {
    method: 'POST',
    body: formData,
    token,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })

  return {
    data,
    error: errors ? new GqlApiError(errors) : undefined,
  }
}
