import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { PostType } from 'graphql/types'
import { useStore } from 'lib/store'
import { isValidChain } from 'lib/web3/chains'
import { useObserver } from 'mobx-react-lite'
import { Post } from 'models/Post'
import { Profile } from 'models/Profile'
import { usePrivy } from '@privy-io/react-auth'
import { useEffect } from 'react'

const resolvePaymentOption = (post?: Post) => {
  if (!post) {
    return PaymentOptions.TIP_PROFILE
  }
  switch (true) {
    case post.type === PostType.EXCLUSIVE && !post.isVisible:
      return PaymentOptions.EXCLUSIVE_POST
    case post.type === PostType.SUBSCRIPTION && !post.isSubscribed:
      return PaymentOptions.SUBSCRIBE_PROFILE
    default:
      return PaymentOptions.TIP_POST
  }
}

interface Props {
  post?: Post
  profile?: Profile
  paymentType?: PaymentOptions
}

export const useHandlePayment = ({ post, profile, paymentType }: Props) => {
  const store = useStore()
  const { ready, linkWallet, login } = usePrivy()

  const _profile = post ? post.profile : profile

  useEffect(() => {
    store.ui.registerGatedActionCb(login)
  }, [store, linkWallet])

  return useObserver(() => {
    const handlePayment = async () => {
      if (store.ui.handleGatedAction()) return

      if (!_profile) {
        console.error('Cannot handle payment without a creator profile to pay')
        return
      }

      // --- guards
      switch (true) {
        case !ready:
        case store.wallets.isConnecting:
          // ! Wallet linking (see WalletStateSync, wallets.setWallets & wallet.connectEagerly) still in progress.
          // TODO: loading modal/state across actions that trigger this
          break
        // wallet is not connected
        case !store.wallets.connected:
          console.info('---- No wallets detected -----')
          linkWallet()
          break
        // connected wallet is connectd to incorrect chainId
        case !isValidChain(store.wallets.connected?.chainId):
          console.info('---- wallet mapped to incorrect chain -----')
          console.log('[currentChain]', store.wallets.connected?.chainId)
          store.ui.openNetworkSelector()
          break
        default:
          console.log('---- useHandlePayment -----')
          console.log('---- useHandlePayment -----')
          console.log('---- useHandlePayment -----')
          console.log({
            post,
            profile: _profile,
            paymentType: paymentType ? paymentType : resolvePaymentOption(post)
          })

          store.transaction.setTransaction({
            post,
            profile: _profile,
            paymentType: paymentType ? paymentType : resolvePaymentOption(post)
          })
          store.ui.togglePaymentModal()
          break
      }
    }

    return { handlePayment }
  })
}
