import { ComponentProps } from '@stitches/react'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

interface Props extends ComponentProps<typeof Flex> {
  bio?: string | null
}

export const ProfileBio: React.FC<Props> = ({ bio, css }) =>
  bio ? (
    <Flex css={{ padding: '4px 16px 8px 16px', ...css }}>
      <Flex
        css={{
          flex: '1',
          margin: '0',
        }}
      >
        <Text
          css={{
            color: '$gray800',
          }}
          type='body3'
        >
          {bio || ''}
        </Text>
      </Flex>
    </Flex>
  ) : null
