import { Button, ButtonProps } from 'common/Button'
import { PopupDialog, PopupDialogProps } from 'common/PopupDialog'
import { PopupDialogContent } from 'common/PopupDialog/PopupDialogContent'
import { SnackBar } from 'common/SnackBar'
import snackBar from 'common/SnackBar/config'
import { toast } from 'components/common/Toast'
import { useToggleFollowProfile } from 'hooks/useToggleFollowProfile'
import { useStore } from 'lib/store'
import { CSS, theme } from 'lib/theme'
import { NSFW_EVENT } from 'lib/tracking/types'
import { observer } from 'mobx-react-lite'
import { Profile } from 'models/Profile'
import { useContext, useState } from 'react'
import { LayoutContext } from 'shared/Layout'
import { ReportMenu } from 'shared/ReportMenu'

const buttonCss: CSS = {
  textTransform: 'capitalize',
  fontWeight: '400',
  backgroundColor: theme.colors.gray400,
  border: `1px solid ${theme.colors.gray500}`,
  borderRadius: '0px',
  '&:hover': {
    backgroundColor: theme.colors.gray500
  }
}

const DeletePostErrorButton = (props: ButtonProps) => (
  <Button
    theme="secondary"
    css={{
      color: 'red',
      borderRadius: '3px',
      '&:hover': {
        color: 'red'
      }
    }}
    {...props}
  >
    Delete
  </Button>
)

export interface ProfileBannerMoreMenuProps extends PopupDialogProps {
  profile: Profile
}

const _ProfileBannerMoreMenu: React.FC<ProfileBannerMoreMenuProps> = ({
  isOpen,
  onIgnore,
  profile,
  css
}) => {
  const { canonical } = useContext(LayoutContext)
  const store = useStore()
  const [reportMenuIsOpen, setReportMenuIsOpen] = useState(false)
  const UTM_CAMPAIGN = `?utm_source=share-profile&utm_medium=interface&utm_campaign=${store.feed.selectedProfile?.profile?.username}`
  const { toggleFollowProfile } = useToggleFollowProfile(profile)

  return (
    <>
      <PopupDialog isOpen={isOpen} onIgnore={onIgnore}>
        <PopupDialogContent css={css}>
          <Button
            theme="secondary"
            css={buttonCss}
            onClick={() => {
              navigator.clipboard.writeText(canonical! + UTM_CAMPAIGN)
              toast({
                message: 'Copied address!',
                type: 'success'
              })
              store.analytics.track(NSFW_EVENT.PROFILE_SHARE, {
                profileId: profile.profileId,
                username: profile.username
              })
            }}
          >
            Share
          </Button>
          <Button theme="secondary" css={buttonCss} onClick={toggleFollowProfile}>
            {profile.followingActionLabel}
          </Button>
          {store.user.account && (
            <Button theme="danger" css={buttonCss} onClick={() => setReportMenuIsOpen(true)}>
              Report
            </Button>
          )}
        </PopupDialogContent>
      </PopupDialog>
      <ReportMenu
        isOpen={reportMenuIsOpen}
        onIgnore={() => setReportMenuIsOpen(false)}
        profile={profile}
      />
    </>
  )
}

export const ProfileBannerMoreMenu = observer(_ProfileBannerMoreMenu)
