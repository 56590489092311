import { isServer } from 'lib/nextjs'
import type { SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import type { SendBirdState, SendBirdStateConfig } from 'SendbirdUIKitGlobal'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'

// Wrapper for this context sdk jazz
export const useSbState = () => {
  const context = useSendbirdStateContext()

  if (!context || isServer())
    return {
      userId: '',
      loading: false,
      sdk: {} as SendbirdGroupChat,
      config: {} as SendBirdStateConfig,
      context: {} as SendBirdState,
    }

  const { loading, initialized, error, sdk } = context.stores.sdkStore
  const { userId } = context.config

  return {
    userId, // sendbird user id
    loading,
    initialized,
    error,
    sdk: sdk as unknown as SendbirdGroupChat,
    config: context.config,
    // sb selectors take the whole object
    context,
  }
}
