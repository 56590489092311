import { Button } from 'common/Button'
import { ComponentProps, theme } from 'lib/theme'

export const FollowButton: React.FC<ComponentProps<typeof Button>> = ({
  children,
  css,
  ...props
}) => (
  <Button
    pill
    css={{
      width: 'auto',
      border: `1px solid ${theme.colors.gray400}`,
      backgroundColor: theme.colors.gray500,
      fontSize: '12px',
      lineHeight: '16px',
      ...css
    }}
    {...props}
  >
    {children}
  </Button>
)
