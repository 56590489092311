import { Sx, Tooltip } from '@mantine/core'
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { Audience } from 'graphql/types'
import { useHandlePayment } from 'hooks/useHandlePayment'
import { useSbState } from 'lib/sendbird/hooks/useSbState'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import { Profile } from 'models/Profile'
import { Flex } from 'primitives/Flex'
import { MouseEventHandler, useEffect, useState } from 'react'

interface Props {
  profile: Profile
  label?: string
  css?: Sx
}

const _ProfileChatButton = ({ label = 'Message', profile, css = {} }: Props) => {
  const store = useStore()
  const { context: sbContext, userId: sbCurrentUserId, sdk: sbSDK } = useSbState()
  const createChannel = sendbirdSelectors.getCreateGroupChannel(sbContext)

  const [isMessagable, setIsMessagable] = useState(false)
  const [isLoadingChatChannel, setIsLoadingChatChannel] = useState(false)

  // If the audience is not subscribers, return true
  const isSubscribed =
    profile?.messaging?.audience === Audience.SUBSCRIBERS
      ? store.user.isSubscribed(profile?.profileId)
      : true

  const hasMessagePreferences = profile?.messaging?.rate !== null

  const handleToolTipLabel = () => {
    switch (true) {
      case !isSubscribed:
        return 'Only subscribed users can message.'
      case !isMessagable:
        return 'Creator is not registered to receive messages yet.'
      case !hasMessagePreferences:
        return 'Creator has not configured their messaging options yet.'
      default:
        return 'Creator can not receive messages yet. '
    }
  }

  const isDisabled = !isMessagable || !hasMessagePreferences || !isSubscribed

  const { handlePayment: handleCreditsPurchase } = useHandlePayment({
    profile: store.user.account as unknown as Profile,
    paymentType: PaymentOptions.MESSAGE_PACK
  })

  const handleMessageClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    if (profile.isOwner || !profile.isCreator || !isMessagable) return

    const loggedInUserCredits = store.user.messageCredits
    const rate = profile?.messaging?.rate ?? 0
    const userCanMessage = loggedInUserCredits > 0 && loggedInUserCredits > rate
    if (!userCanMessage) {
      handleCreditsPurchase()
      return
    }

    setIsLoadingChatChannel(true)
    const params: any = {}
    params.invitedUserIds = [profile.profileId]
    params.isDistinct = true
    params.operatorUserIds = [sbCurrentUserId]

    createChannel(params)
      .then((channel) => {
        console.log('created channel!', channel)
        store.chat.setChannel(channel)
      })
      .catch(console.error)
      .finally(() => setIsLoadingChatChannel(false))
  }

  useEffect(() => {
    async function findSbUser() {
      if (!sbSDK.createApplicationUserListQuery) return

      const query = sbSDK.createApplicationUserListQuery({
        userIdsFilter: [profile.profileId]
      })
      const users = await query.next()
      const foundUser = users.find((u) => u.userId === profile.profileId)

      setIsMessagable(!!foundUser)
    }
    findSbUser()
  }, [sbSDK, profile.profileId])

  return (
    <Tooltip
      disabled={!isDisabled}
      sx={{ width: '100%', padding: '16px', ...css }}
      label={handleToolTipLabel()}
    >
      <Flex column>
        <Button
          theme={!isDisabled ? 'primary' : 'secondary'}
          disabled={isDisabled}
          loading={isLoadingChatChannel}
          css={{
            padding: '0 16px',
            height: '44px',
            '&:disabled': {
              cursor: 'not-allowed'
            }
          }}
          onClick={handleMessageClick}
        >
          <Flex row center css={{ gap: 5 }}>
            <Icon
              icon="MessageSquare"
              iconSize={14}
              css={{
                marginLeft: '-14px',
                '& .primary.fill': {
                  fill: !isDisabled ? '$gray900' : '$gray700'
                }
              }}
            />
            {label}
          </Flex>
        </Button>
      </Flex>
    </Tooltip>
  )
}

export const ProfileChatButton = observer(_ProfileChatButton)
