import type { ComponentProps } from '@stitches/react'
import { Avatar } from 'common/Avatar'
import { Camera as CameraIcon } from 'common/Icon'
import { Spinner } from 'common/Spinner'
import { toast } from 'components/common/Toast'
import type { UpdateAvatarResponse } from 'graphql/mutations/profiles/updateAvatar'
import { gqlUpdateAvatar } from 'lib/gql/gqlUpdateAvatar'
import { useSbState } from 'lib/sendbird/hooks/useSbState'
import { useStore } from 'lib/store'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { Input } from 'primitives/Input'
import type React from 'react'
import { useRef, useState } from 'react'

export interface UpdateAvatarProps extends ComponentProps<typeof Box> {
  isSettingsModal: boolean
  icon?: React.ReactNode
  children?: React.ReactNode
  avatar?: React.ReactNode
}

const _UpdateAvatar: React.FC<UpdateAvatarProps> = ({ isSettingsModal, icon, children, css }) => {
  const store = useStore()
  const sb = useSbState()
  const [isLoading, setIsLoading] = useState(false)
  const [showIcon, setShowIcon] = useState(false)
  console.log('showIcon', showIcon)
  const [isAvatarUploaded, setIsAvatarUploaded] = useState(
    Boolean(store.user?.account?.avatar) ?? false
  )
  const inputRef = useRef<HTMLInputElement>(null)
  console.log('store.user?.account?.avatar?.url', Boolean(store.user?.account?.avatar))
  const uploadAvatar = async (file: File) => {
    setIsAvatarUploaded(true)
    setIsLoading(true)
    const { data, error } = await gqlUpdateAvatar<UpdateAvatarResponse>(file)

    if (error || !data) {
      console.error(error)
      toast({
        message: 'Unable to update avatar',
        type: 'error'
      })
      return null
    }

    store.user.updateAvatar(data.updateAvatar.avatar)

    if (sb.sdk.updateCurrentUserInfo) {
      sb.sdk
        .updateCurrentUserInfo({
          profileUrl: data.updateAvatar.avatar.url
        })
        .catch((err) => {
          console.error(
            'Error updating SB profile on avatar upload (sdk.updateCurrentUserInfo)',
            err
          )
        })
    }
    setIsLoading(false)
  }

  const handleUploadImage = () => {
    inputRef.current?.click()
  }

  return (
    <Flex
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      css={{
        position: 'relative',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover [data-icon-wrapper]': { opacity: isLoading ? '1' : '0.5' },
        borderRadius: '50%',
        ...css
      }}
    >
      <Avatar
        src={store.user?.account?.avatar?.url}
        round={false}
        size={isSettingsModal ? 'medium' : 'xxlarge'}
        css={{
          visibility: isLoading ? 'hidden' : 'visible',
          opacity: '0'
        }}
      />
      <Input
        type="file"
        accept="image/*"
        disabled={isLoading}
        ref={inputRef}
        css={{
          padding: '0px',
          height: 'auto',
          width: 'auto',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          fontSize: '0px',
          userSelect: 'none',
          '&::-webkit-file-upload-button': {
            visibility: 'hidden',
            userSelect: 'none'
          },
          opacity: '0'
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          const file = e?.currentTarget?.files?.[0]
          if (file) {
            uploadAvatar(file)
          }
          e.currentTarget.value = ''
        }}
      />
      <Flex
        data-icon-wrapper
        role="button"
        onClick={handleUploadImage}
        css={{
          position: 'absolute',
          top: '6px',
          left: '8px',
          right: '0',
          bottom: '0',
          borderRadius: '100%',
          pointerEvents: 'none',
          transition: 'opacity 200ms ease',
          opacity: '1'
        }}
      >
        {!isAvatarUploaded && children}
        {isLoading ? (
          <Box
            css={{
              position: 'absolute',
              top: '52%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Spinner />
          </Box>
        ) : !isAvatarUploaded ? (
          icon ||
          (showIcon && (
            <CameraIcon
              css={{
                backgroundColor: '$gray100',
                borderRadius: isSettingsModal ? 0 : '50%',
                padding: 10,
                height: isSettingsModal ? 30 : 60,
                width: isSettingsModal ? 30 : 60,
                position: 'absolute',
                top: '48%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          ))
        ) : (
          ''
        )}
      </Flex>
    </Flex>
  )
}

export const UpdateAvatar = observer(_UpdateAvatar)
