import { Button } from 'common/Button'
import { PopupDialog, PopupDialogProps } from 'common/PopupDialog'
import { PopupDialogContent } from 'common/PopupDialog/PopupDialogContent'
import { toast } from 'components/common/Toast'
import { REPORT_MUTATION } from 'graphql/mutations/report'
import {
  Comment,
  CreateReportInput,
  MutationCreateReportArgs,
  ReportItem,
  ReportReason
} from 'graphql/types'
import { useStore } from 'lib/store'
import { theme } from 'lib/theme'
import { NSFW_EVENT } from 'lib/tracking/types'
import { observer } from 'mobx-react-lite'
import { Post } from 'models/Post'
import { Profile } from 'models/Profile'
import { useCallback, useEffect } from 'react'
import { useMutation } from 'urql'

export interface ReportMenuProps extends PopupDialogProps {
  profile?: Profile
  post?: Post
  comment?: Comment
}

const _ReportMenu: React.FC<ReportMenuProps> = ({
  isOpen,
  onIgnore,
  profile,
  post,
  comment,
  css
}) => {
  const store = useStore()
  const [{ fetching: isLoadingCreatePost, error }, createReport] = useMutation<
    { createReport: boolean },
    MutationCreateReportArgs
  >(REPORT_MUTATION)

  const getReportType = useCallback(() => {
    switch (true) {
      case profile !== undefined:
        return ReportItem.PROFILE
      case post !== undefined:
        return ReportItem.POST
      case comment !== undefined:
        return ReportItem.COMMENT
    }
  }, [profile, post, comment])

  useEffect(() => {
    if (isOpen) {
      store.analytics.track(NSFW_EVENT.REPORT_START, {
        reportedItemType: getReportType()
      })
    }
  }, [store.analytics, isOpen, getReportType])

  const reportReasons = (Object.keys(ReportReason) as (keyof typeof ReportReason)[]).map((key) => {
    return ReportReason[key]
  })

  const handleClickReason = async (reason: ReportReason) => {
    let reportInput: CreateReportInput | undefined = undefined
    let username: string
    switch (true) {
      case profile !== undefined:
        reportInput = {
          reportedItemId: profile!.profileId!,
          reportedItemType: ReportItem.PROFILE,
          reason: reason,
          profileId: profile!.profileId!
        }
        username = profile!.username
        break
      case post !== undefined:
        reportInput = {
          reportedItemId: post!.postId!,
          reportedItemType: ReportItem.POST,
          reason: reason,
          profileId: post!.profile!.profileId
        }
        username = post!.profile!.username
        break
      case comment !== undefined:
        reportInput = {
          reportedItemId: comment!.commentId,
          reportedItemType: ReportItem.COMMENT,
          reason: reason,
          profileId: comment!.author.profileId
        }
        username = comment!.author.username
        break
      default:
        toast('Opps, Something went wrong!, Please contact support.')
    }
    if (reportInput !== undefined) {
      await createReport({ input: reportInput }).then((data) => {
        if (data?.data?.createReport) {
          toast({
            message: 'Thank you for submitting the report',
            type: 'success'
          })
          store.analytics.track(NSFW_EVENT.REPORT_COMPLETE, {
            ...reportInput,
            username
          })
          onIgnore()
        }
      })
    }
    if (error) {
      toast({
        message: 'Failed to submit report',
        type: 'error'
      })
      onIgnore()
    }
  }

  return (
    <PopupDialog isOpen={isOpen} onIgnore={onIgnore} css={css}>
      <PopupDialogContent>
        {reportReasons.map((reason) => (
          <Button
            css={{
              textTransform: 'capitalize',
              fontWeight: '400',
              backgroundColor: theme.colors.gray400,
              border: `1px solid ${theme.colors.gray500}`,
              borderRadius: '0px',
              '&:hover': {
                backgroundColor: theme.colors.gray500
              }
            }}
            key={reason}
            onClick={() => {
              handleClickReason(reason)
            }}
          >
            {reason.replace(/_/g, ' ').toLowerCase()}
          </Button>
        ))}
      </PopupDialogContent>
    </PopupDialog>
  )
}
export const ReportMenu = observer(_ReportMenu)
