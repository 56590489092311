import { ComponentProps } from '@stitches/react'
import { MetricLink } from 'common/MetricLink'
import { MetricLinksContainer } from 'common/MetricLink/MetricLinkContainer'
import { AccountType } from 'graphql/types'
import { observer } from 'mobx-react-lite'
import { Profile } from 'models/Profile'
import { useRouter } from 'next/router'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

export interface ProfileStatsProps extends ComponentProps<typeof Flex> {
  profile: Profile
}

const _ProfileStats: React.FC<ProfileStatsProps> = ({ css, profile }) => {
  const { asPath } = useRouter()

  const STATS_LINKS: {
    label: string
    value?: number
    href?: string
    visible: boolean
    active?: boolean
  }[] = [
    {
      label: 'Posts',
      value: profile.postsCount,
      visible: profile.accountType === AccountType.CREATOR,
      href: `/${profile.username}`,
      active: asPath === `/${profile.username}`
    },
    {
      label: 'Followers',
      value: profile.followersCount,
      visible: profile.accountType === AccountType.CREATOR,
      href: `/${profile.username}/followers`,
      active: asPath === `/${profile.username}/followers`
    },
    {
      label: 'Following',
      value: profile.followingCount,
      visible: true,
      href: `/${profile.username}/following`,
      active: asPath === `/${profile.username}/following`
    },
    {
      label: 'Subscribers',
      value: profile.activeSubscriberCount,
      visible: profile.accountType === AccountType.CREATOR
    }
  ]

  return (
    <MetricLinksContainer
      css={{
        '& > *:not(:last-child)': {
          marginRight: '4px'
        },
        padding: profile.isCreator ? '0px 16px 8px' : '0 16px 16px',
        ...css
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {STATS_LINKS.filter((x) => x.visible).map(({ label, value, href, ...props }) => (
        <MetricLink
          nounderline
          key={label}
          href={href}
          css={{
            display: 'block',
            padding: '6px',
            borderRadius: '8px',
            textAlign: 'center',
            flexBasis: '100%',
            minWidth: 'max-content',
            backgroundColor: '$gray400',
            ...(href
              ? {
                  cursor: 'pointer',
                  '@media (hover: hover)': {
                    '&:hover': {
                      backgroundColor: '$gray600'
                    }
                  }
                }
              : {
                  backgroundColor: '$gray100',
                  cursor: 'not-allowed'
                })
          }}
          {...props}
        >
          <Text type="body3">{label}</Text>
          <Text type="body4" css={{ color: '$gray800' }}>
            {value}
          </Text>
        </MetricLink>
      ))}
    </MetricLinksContainer>
  )
}

export const ProfileStats = observer(_ProfileStats)
