import { usePrivy } from '@privy-io/react-auth'
import type { ComponentProps } from '@stitches/react'
import { Avatar } from 'common/Avatar'
import { Button } from 'common/Button'
import { FollowButton } from 'common/FollowButton'
import { Icon } from 'common/Icon'
import { DEFAULT_HOME_ROUTE } from 'config'
import { PaymentOptions } from 'components/modals/PaymentModal/types'
import { UpdateAvatar } from 'containers/UpdateAvatar'
import { WarningModal } from 'containers/WarningModal/WarningModal'
import { Audience } from 'graphql/types'
import { useHandlePayment } from 'hooks/useHandlePayment'
import { useToggleFollowProfile } from 'hooks/useToggleFollowProfile'
import { useStore } from 'lib/store'
import { capitalizeString } from 'lib/strings'
import { NSFW_EVENT } from 'lib/tracking/types'
import { observer } from 'mobx-react-lite'
import type { Profile } from 'models/Profile'
import { useRouter } from 'next/router'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { Heading } from 'primitives/Heading'
import { Text } from 'primitives/Text'
import { type MouseEventHandler, useState } from 'react'
import { ProfileBio } from 'shared/ProfileBio'
import { ProfileChatButton } from 'shared/ProfileChatButton'
import { ProfileOptions } from 'shared/ProfileOptions'
import { Card } from '../../primitives/Card'
import { ProfileStats } from '../ProfileStats/ProfileStats'

export interface ProfileCardProps extends ComponentProps<typeof Box> {
  profile: Profile
}

const _ProfileCard: React.FC<ProfileCardProps> = ({ css, profile, ...props }) => {
  const store = useStore()
  const router = useRouter()

  const username = store.user.account?.username

  const avatar = profile.isOwner ? store.user.account?.avatar : profile?.avatar
  const isSubscribed = store.user.isSubscribed(profile.profileId)
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)

  const { toggleFollowProfile } = useToggleFollowProfile(profile)
  const { handlePayment: handleTip } = useHandlePayment({
    profile,
    paymentType: PaymentOptions.TIP_PROFILE
  })
  const { handlePayment: handleSub } = useHandlePayment({
    profile,
    paymentType: PaymentOptions.SUBSCRIBE_PROFILE
  })

  const handleFollowProfile: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    toggleFollowProfile(e).then(({ success, following }) => {
      if (success && following) {
        store.analytics.track(NSFW_EVENT.PROFILE_FOLLOW, {
          profileId: profile.profileId,
          username: profile.username
        })
      }
    })
  }

  const handleTipOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    handleTip()
  }

  const handleSubOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    handleSub()
  }

  const handleLogout = () => {
    setIsLogoutOpen(false)
    setTimeout(() => {
      store.user.logout()
      store.ui.closeProfileSettingsModal()
      store.analytics.track(NSFW_EVENT.SETTINGS_LOGOUT)
      router.push(DEFAULT_HOME_ROUTE)
    }, 250)
  }

  return (
    <>
      <Card
        css={{
          width: '100%',
          borderRadius: '6px',
          overflowX: 'hidden',
          marginBottom: '16px'
        }}
      >
        <Flex css={{ ...css }} column {...props}>
          <Box>
            <Flex
              column
              css={{
                flex: '1',
                paddingLeft: '16px',
                justifyContent: 'space-between'
              }}
            >
              <Flex fullHeight>
                <Flex column css={{ flex: 1, marginTop: '16px' }}>
                  <Flex css={{ justifyContent: 'space-between' }}>
                    <Flex
                      css={{
                        width: 'fit-content',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Heading as="h4">{profile.displayName}</Heading>
                    </Flex>
                    {!profile.isOwner && (
                      <Button
                        follow
                        css={{
                          width: '80px',
                          height: '24px',
                          margin: '0 0 0 4px'
                        }}
                        onClick={handleFollowProfile}
                      >
                        {profile.followingActionLabel}
                      </Button>
                    )}
                  </Flex>
                  <Text type="body2" subText>
                    @{profile.username}
                  </Text>
                  <Flex
                    css={{
                      minHeight: '25px',
                      marginTop: '10px',
                      width: '288px',
                      flexWrap: 'wrap'
                    }}
                  >
                    {profile?.tags?.map(({ tagId, value }) => (
                      <FollowButton
                        key={tagId}
                        css={{
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          marginLeft: '3px',
                          marginBottom: '3px'
                        }}
                      >
                        {capitalizeString(value).replace(/-/g, ' ')}
                      </FollowButton>
                    ))}
                  </Flex>
                </Flex>
                <ProfileOptions profile={profile} />
              </Flex>
            </Flex>
            <Flex column css={{ padding: '10px' }}>
              <Avatar
                src={avatar?.url}
                size="xlarge"
                round={false}
                username={username}
                css={{
                  height: '300px',
                  width: '100%',
                  opacity: '1'
                }}
              >
                {profile.isOwner && <UpdateAvatar isSettingsModal={false} />}
              </Avatar>
            </Flex>
            <ProfileStats profile={profile} />
            <ProfileBio bio={profile.bio} />
            {!profile.isOwner && profile.isCreator && (
              <>
                <Flex css={{ margin: '12px 0 16px' }}>
                  <Button
                    theme="secondary"
                    disabled={isSubscribed}
                    css={{
                      margin: '0 4px 0 16px',
                      height: '44px',
                      cursor: !isSubscribed ? 'pointer' : 'not-allowed'
                    }}
                    onClick={handleSubOnClick}
                  >
                    <Flex row center css={{ gap: 5 }}>
                      <Icon
                        icon="Bell"
                        iconSize={13}
                        css={{
                          cursor: isSubscribed ? 'not-allowed !important' : 'cursor',
                          marginLeft: '-5px',
                          '& .primary.fill': {
                            fill: isSubscribed ? '$gray800' : '$blue100'
                          }
                        }}
                      />
                      {isSubscribed ? 'Subscribed' : 'Subscribe'}
                    </Flex>
                  </Button>
                  <Button
                    theme="secondary"
                    css={{
                      margin: '0 16px 0 4px',
                      height: '44px',
                      fontSize: '14px'
                    }}
                    onClick={handleTipOnClick}
                  >
                    <Flex row center css={{ gap: 5 }}>
                      <Icon
                        icon="Money"
                        iconSize={15}
                        css={{
                          marginLeft: '-15px',
                          '& .primary.fill': {
                            fill: '$gold100'
                          }
                        }}
                      />
                      Tip
                    </Flex>
                  </Button>
                </Flex>
                {/* Hide chat button if user preference is set to Nobody */}
                {profile?.messaging?.audience !== Audience.NONE && (
                  <ProfileChatButton profile={profile} css={{ padding: '0 16px 16px 16px' }} />
                )}
              </>
            )}
          </Box>
        </Flex>
      </Card>
      {profile.isOwner && (
        <Button
          theme="secondary"
          css={{ textTransform: 'none', marginBottom: '20px' }}
          onClick={() => {
            setIsLogoutOpen(true)
          }}
        >
          Log Out
        </Button>
      )}
      <WarningModal
        isOpen={isLogoutOpen}
        onIgnore={() => setIsLogoutOpen(false)}
        description="Are you sure you want to disconnect your wallet and logout?"
        confirmButtonLabel="Yes"
        rejectButtonLabel="No"
        onConfirm={() => handleLogout()}
        onReject={() => setIsLogoutOpen(false)}
      />
    </>
  )
}

export const ProfileCard = observer(_ProfileCard)
