import { animated } from '@react-spring/web'
import { styled, theme } from 'lib/theme'

export const Card = styled(animated.div, {
  position: 'relative',
  color: theme.colors.gray900,
  backgroundColor: theme.colors.gray300,
  overflow: 'hidden',
  boxShadow: theme.shadows.sm,
  touchAction: 'none',
  width: '100%',
})
