import { PROFILE_DESKTOP_RIGHT_COL_HEIGHT } from 'config'
import { styled } from 'lib/theme'
import { RightColumn } from 'shared/Layout'

export const DesktopFeedRightColumn = styled(RightColumn, {
  margin: 0,
  marginTop: '16px',
  height: PROFILE_DESKTOP_RIGHT_COL_HEIGHT,
  overflowY: 'auto',
})
