import { ComponentProps } from '@stitches/react'
import { Button } from 'primitives/Button'
import { Flex } from 'primitives/Flex'
import { Icon } from 'common/Icon'
import { ProfileBannerMoreMenu } from 'shared/ProfileBannerMoreMenu'
import { useState } from 'react'
import { Profile } from 'models/Profile'

interface Props extends ComponentProps<typeof Flex> {
  profile: Profile
  compact?: boolean
}

export const ProfileOptions: React.FC<Props> = ({ profile, css, compact }) => {
  const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false)

  return (
    <>
      <Flex column css={{ marginTop: compact ? '0' : '10px', ...css }}>
        {!profile.isOwner && (
          <>
            <Button
              icon
              onClick={(e) => {
                e.stopPropagation()
                setMoreMenuIsOpen(true)
              }}
            >
              <Icon icon='More' />
            </Button>
          </>
        )}
      </Flex>
      <ProfileBannerMoreMenu
        isOpen={moreMenuIsOpen}
        onIgnore={(e) => {
          e?.stopPropagation()
          setMoreMenuIsOpen(false)
        }}
        profile={profile}
      />
    </>
  )
}
